<template>
    <div>
        This is the redirect to Install Restart page.
    </div>
</template>
<script>
export default {
    name: "InstallRestart",
}
</script>
<style lang="scsss" scoped>

</style>